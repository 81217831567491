<template>
  <div class="um-banner-header">
    <div><img src="@/assets/logoWhite.svg" width="27" height="27" /></div>
    <div>
      <a
        class="body bold"
        href="https://uman.ai/"
        target="_blank"
        rel="noopener noreferrer"
        >visit uman.ai - website</a
      >
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.um-banner-header {
  background: $blue;
  width: 100%;
  height: 2.5rem;
  display: flex;
  justify-content: space-between;
  z-index: 3;
  position: relative;
  div {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 6px;
    font-weight: 600;
    a {
      color: white;
    }
    a:hover {
      text-decoration: underline;
    }
  }
}
</style>

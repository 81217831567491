var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "um-banner-header" }, [
      _c("div", [
        _c("img", {
          attrs: {
            src: require("@/assets/logoWhite.svg"),
            width: "27",
            height: "27"
          }
        })
      ]),
      _c("div", [
        _c(
          "a",
          {
            staticClass: "body bold",
            attrs: {
              href: "https://uman.ai/",
              target: "_blank",
              rel: "noopener noreferrer"
            }
          },
          [_vm._v("visit uman.ai - website")]
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
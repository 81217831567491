<template>
  <div class="onboarding-template--wrapper">
    <WebsiteBanner />
    <div class="page-container row" @keyup.enter="onSubmit">
      <div class="page-container--content">
        <div class="content">
          <p v-if="isFailed" class="um-header--subtext">
            Uh oh. That's an invalid code.
          </p>
          <p v-else class="um-header--subtext">Loading...</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { successToast } from '@/util'
import WebsiteBanner from '@c/components/headers/WebsiteBanner'

export default {
  components: { WebsiteBanner },
  props: {
    code: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      isFailed: false
    }
  },

  created() {
    this.verify()
  },

  methods: {
    ...mapActions(['verifyUserEmail']),

    async verify() {
      try {
        await this.verifyUserEmail({
          code: this.code
        })
        this.$router.push({ name: 'login' })
        successToast(
          this,
          'Email address confirmed, you can now log in to your workspace.'
        )
      } catch {
        this.isFailed = true
      } finally {
        this.$store.commit('STOP_LOADING')
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "onboarding-template--wrapper" },
    [
      _c("WebsiteBanner"),
      _c(
        "div",
        {
          staticClass: "page-container row",
          on: {
            keyup: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.onSubmit.apply(null, arguments)
            }
          }
        },
        [
          _c("div", { staticClass: "page-container--content" }, [
            _c("div", { staticClass: "content" }, [
              _vm.isFailed
                ? _c("p", { staticClass: "um-header--subtext" }, [
                    _vm._v(" Uh oh. That's an invalid code. ")
                  ])
                : _c("p", { staticClass: "um-header--subtext" }, [
                    _vm._v("Loading...")
                  ])
            ])
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }